import { Icon } from "@material-ui/core";
// import {HomeIcon} from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./Header.module.css";
import MailOutlineIcon from '@mui/icons-material/MailOutline';


const Header: React.FC = () => {
    return (
        <div>
            <div className={classes.header}>
                <nav className={classes.nav}>
                    <NavLink className={({ isActive }) => isActive ? classes.active : classes.link} to='/contact'>Contact</NavLink>
                    <NavLink className={({ isActive }) => isActive ? classes.active : classes.link} to='/cv'>Resume</NavLink>
                    {/*<NavLink className={({ isActive }) => isActive ? classes.active : classes.link} to='/dev'>Dev</NavLink>*/}
                    {/*<NavLink className={({ isActive }) => isActive ? classes.active : classes.link} to='/photos'>Photos</NavLink>*/}
                    {/*<NavLink className={({ isActive }) => isActive ? classes.active : classes.link} to='/social'>Social networks</NavLink>*/}
                    <NavLink className={({ isActive }) => isActive ? classes.active : classes.link} to='/'>
                        <HomeIcon sx={{ fontSize: 40}} />
                    </NavLink>
                </nav>
            </div>
        </div>
    )
}

export default Header;

import React from "react";
import classes from "./header/Header.module.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const Contact: React.FC = () => {
    return (
        <div className="coinOutputs">
            <h2 className="coinOutputs--white">
                {/*<h3 className="coinOutputs--violet">*/}
                {/*    You can contact me*/}
                {/*</h3>*/}
            </h2>
            <h2 className="coinOutputs--white">
                <h3 className="coinOutputs--violet">
                    <a href="https://github.com/KeyTea"><GitHubIcon className="icon" sx={{ fontSize: 100}} /></a>
                    <a href="https://www.linkedin.com/in/kaytvy"><LinkedInIcon className="icon" sx={{ fontSize: 100}} /></a>
                    <a href="https://www.facebook.com/kaytvy"><FacebookIcon className="icon" sx={{ fontSize: 100}} /></a>
                    <a href="https://www.instagram.com/keytea/"><InstagramIcon className="icon" sx={{ fontSize: 100}} /></a>
                    <a href="https://twitter.com/kaytvy"><TwitterIcon className="icon" sx={{ fontSize: 100}} /></a>
                    <a href="mailto:kaytvy@gmail.com"><MailOutlineIcon className="icon" sx={{ fontSize: 100}}/></a>
                    <a href="https://t.me/kaytea"><TelegramIcon className="icon" sx={{ fontSize: 100}}/></a>
                    <a href="https://wa.me/+972559409452"><WhatsAppIcon className="icon" sx={{ fontSize: 100}}/></a>
                    <a href="tel:+972559409452"><PhoneIcon className="icon" sx={{ fontSize: 100}}/></a>
                </h3>
            </h2>
        </div>
    )
}

export default Contact;

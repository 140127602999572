import React, {useEffect, useState} from "react";
import {useAppDispatch} from "./app/hooks";
import {getToken, Link, setGithubError, setUsername} from "./githubReducer";
import {default as axios} from "axios";

const LoginWithGithub: React.FC = () => {
    const [input, setInput] = useState('');
    const dispatch = useAppDispatch();
    // useEffect(() => {dispatch(getToken(username))});
    const githubUrl: Link = {
        url: "https://github.com/",
        username: "",
        end: "/oauth/authorize"
    }
    // const axios = require('axios').default;
    const [link, setLink] = useState('');

    return (
        <div><div className="coinOutputs">
            <h2 className="coinOutputs--white">
                <h3 className="coinOutputs--violet">
                    Login with
                </h3>
                {/*<a href="https://login.microsoftonline.com/80c1bb1e-e7df-4e52-bd98-40ca0bc225e0/oauth2/v2.0/authorize?*/}
                {/*client_id=683bbba5-e8df-4b2f-8cc6-3e947d8e3207*/}
                {/*&response_type=code*/}
                {/*&redirect_uri=https%3A%2F%2Fkt%2Dy%2Ecom%2F*/}
                {/*&response_mode=query*/}
                {/*&scope=offline_access%20user.read%20mail.read*/}
                {/*&state=12345">login with Microsoft</a>*/}
                    <a href="https://login.microsoftonline.com/80c1bb1e-e7df-4e52-bd98-40ca0bc225e0/oauth2/v2.0/authorize?client_id=683bbba5-e8df-4b2f-8cc6-3e947d8e3207&response_type=code&redirect_uri=https%3A%2F%2Fkt%2Dy%2Ecom%2F&response_mode=query&scope=offline_access%20user.read%20mail.read&state=12345">Login with Microsoft</a>
                <br/><a href="https://github.com/login/oauth/authorize?client_id=1726d4fb5413cc82dfaa&scope=user%20repo%20workflow%20admin:org%20admin:repo_hook%20admin:org_hook%20admin:gpg_key%20read:org%20write:packages%20delete:packages%20admin:public_key%20gist%20notifications%20delete_repo%20write:discussion%20admin:enterprise">Login with Github kt</a>
                <br/><a href="https://github.com/login/oauth/authorize?client_id=a0e6f077f1af2d0f6b7e&scope=user%20repo%20workflow%20admin:org%20admin:repo_hook%20admin:org_hook%20admin:gpg_key%20read:org%20write:packages%20delete:packages%20admin:public_key%20gist%20notifications%20delete_repo%20write:discussion%20admin:enterprise">Login with Github app</a>
                <br/><a href="https://id.heroku.com/oauth/authorize?client_id=8d02af61-8bb0-4ae0-81f4-76ead794f733&response_type=code&scope=global&state=https://kt-y.com/">Login with Heroku kt app</a>
                <br/><a href="https://gitlab.com/oauth/authorize?client_id=4d94cb1adb84bf5e2ee54847d4c5b1e917a6fd5b59e8b5085583badcea5209a9&redirect_uri=https://kt-y.com/&response_type=code">Login with Gitlab kt app</a>

                <br/><a href="https://gitlab.com/oauth/authorize?client_id=73602aea9e38950469778bef1106847f066b7e5bf25c997f6104e5b70dd3bb5b&redirect_uri=https://kt-y.com/&response_type=code">Login with Gitlab Levi app</a>
                <br/><a href="https://gitlab.com/oauth/authorize?client_id=d12347c088d70f8fe04493340b213e23963053681da7c5f372924fdc70d3be0e&redirect_uri=https://kt-y.com/&response_type=code">Login with Gitlab Levi 2 app</a>
                <br/><a href="https://gitlab.com/oauth/authorize?client_id=36faf3f1021ae6155a72b292029e8f56b710d7dafc4966870548146ae7d144fc&redirect_uri=https://kt-y.com/&response_type=code">Login with Gitlab Levi 3 app</a>
                <br/><a href="https://gitlab.com/oauth/authorize?client_id=58347256f36d906cd21dcdc28b76b16dcd1a07e298cee497bcc67ae03dbbc6a5&redirect_uri=https://kt-y.com/&response_type=code">Login with Gitlab Levi 4 app</a>
                {/*<br/><a href="https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=4f5a7e9e-f13e-317e-a989-8ac0c74f6203&scope=read write&redirect_uri=https://kt-y.com/ https://connect-inspector.services.atlassian.com/page/addon?addonKey=166fba34-083f-4c96-82c7-27700912053a&state=YOUR_USER_BOUND_VALUE&response_type=code&prompt=consent">Jira</a>*/}
                <br/><a href="https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=4f5a7e9e-f13e-317e-a989-8ac0c74f6203&scope=read write&redirect_uri=https://kt-y.com/&response_type=code&prompt=consent">Jira</a>
            </h2>
        </div>
        </div>
    )
}

export default LoginWithGithub;

import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import LoginWithGithub from "./LoginWithGithub";
import Header from "./components/header/Header";
import Main from "./components/Main";
import Resume from "./components/Resume";
import Social from "./components/Social";
import {Route, Routes} from "react-router-dom";
import Photos from './components/Photos';
import Contact from "./components/Contact";
import Dev from "./components/Dev";

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <Header/>
      </header>
        <Routes>
        <Route path="/" element={<Main />} />
        <Route path="cv" element={<Resume />} />
            {/*<Route path="dev" element={<Dev />}/>*/}
        {/*<Route path="photos" element={<Photos />} />*/}
        {/*<Route path="social" element={<Social />} />*/}
        <Route path="contact" element={<Contact />} />
        </Routes>
    </div>
  );
}

export default App;

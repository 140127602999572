import {createAsyncThunk, createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
// import axios from "axios";
import {RootState} from "./app/store";

export type GithubState = {
    username: string;
    accessToken: string;
    refreshToken: string;
    isLoading: boolean,
    error: Error | null;
}

const axios = require('axios').default;

export type Link = {
    url: string,
    username: string,
    end: string,
    query?: string
}

export type Token ={
    accessToken: string,
    refreshToken: string,
}

const initialState: GithubState = {
    username:'',
    accessToken: '',
    refreshToken: '',
    isLoading: true,
    error: null,
}

const API_KEY = "";
const URL = "";
const github = "github";
// const githubError: Error = new Error("Something went wrong");
const githubUrl: Link = {
    url: "https://github.com/",
    username: "",
    end: "/oauth/authorize"
}

//clientId 1726d4fb5413cc82dfaa


// export const getToken = createAsyncThunk<any, string>(
//     github,
//     async (username: string) => {
//         try {
//             const response = await axios.get("https://github.com/login/oauth/authorize");
//             console.log('link: ' + githubUrl.url + username + githubUrl.end);
//             return response.data;
//         } catch (error) {
//             setGithubError(error);
//         }
//     }
// );

export const getToken = createAsyncThunk(
    github,
    async () => {
        const response = await axios.get("https://github.com/login/oauth/authorize");
        console.log('reducer works');
        return response.data;
    }
);

export const githubSlice: Slice = createSlice({
    name: github,
    initialState,
    reducers: {
        setUsername: (state,action: PayloadAction<string>)=> {
            state.username = action.payload;
        },
        setToken: (state, action: PayloadAction<Token>) =>{
            if (action.payload) {
                state.cruise = action.payload;
            }
        },
        setGithubError: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
        },
        handleGithubError: (state) => {
            state.error = null;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            return {...state, isLoading: action.payload};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getToken.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getToken.rejected, (state) => {
                state.isLoading = false;
                // state.error = githubError;
                // state.error = new Error("Something went wrong");

            })
            .addCase(getToken.fulfilled, (state, action: PayloadAction<Token>) => {
                if (action.payload) {
                    state.accessToken = action.payload.accessToken;
                    state.refreshToken = action.payload.refreshToken;
                } else {
                    // state.error = githubError;
                    // state.error = new Error("Something went wrong");
                }
                state.isLoading = false;
            });
    },

});

export const {setGithubError, setIsLoading, handleCruiseError, setToken, setUsername} = githubSlice.actions;
export const selectGithub = (state: RootState) => state.github;
export default githubSlice.reducer;

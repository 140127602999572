import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const Resume: React.FC = () => {
    return (
            <div className="coinOutputs">
                <h2 className="coinOutputs--white">
                    <h3 className="coinOutputs--violet">
                        CV
                    </h3>
                </h2>
            </div>
    )
}

export default Resume;

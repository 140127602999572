import React from "react";
import LoginWithGithub from "../LoginWithGithub";

const Main: React.FC = () => {
    return (
        <div className="coinOutputs">
            {/*<h2 className="coinOutputs--white">*/}
            {/*    <h3 className="coinOutputs--violet">*/}
                    <LoginWithGithub/>
            {/*    </h3>*/}
            {/*</h2>*/}
        </div>
    )
}

export default Main;
